import express from 'express';
import fetch from 'cross-fetch';
import { from, split, HttpLink, ApolloLink, HttpOptions } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import { runtimeConfig } from '../../runtimeConfig';
import { updateUserId } from '../../store/user/actions';
import { setCookie } from '../../store/cookies/actions';
import { GrowthBook } from '@growthbook/growthbook';

// If we're server-side rendering, use our custom fetch.
// Imported dynamically to avoid any issues in the browser
let customFetch = typeof window !== 'undefined' ? fetch : require('./fetch').default;

export default function graphQlLink(store: any, req?: express.Request) {
    const batchHttpLinkParams: BatchHttpLink.Options = {
        uri: runtimeConfig.graphQLEndpoint,
        batchMax: 50,
        batchInterval: 10,
        credentials: 'include',
        fetch: customFetch
    };

    const httpLinkParams: HttpOptions = {
        uri: runtimeConfig.graphQLEndpoint,
        credentials: 'include',
        fetch: customFetch
    };

    if (req?.headers) {
        batchHttpLinkParams.headers = httpLinkParams.headers = {
            Cookie: req?.headers['cookie'] ?? '',
            'user-agent': req?.headers['user-agent'] ?? '',
            'true-client-ip': (req?.headers['true-client-ip'] as string) ?? '',
            'cf-postal-code': (req?.headers['cf-postal-code'] as string) ?? '',
            'cf-ipcountry': (req?.headers['cf-ipcountry'] as string) ?? ''
        };
    }

    // Feature flag support for graphql request batching
    let gb: GrowthBook;
    if (req) {
        // If this is a server-side render, we already have a GB client to use
        gb = req.growthbook;
    } else {
        // If this is client-side, make a new one...
        // Todo - can we get this from the growthbook provider?
        gb = new GrowthBook({
            apiHost: runtimeConfig.growthBookHost ?? 'https://gb.tsr.tools',
            clientKey: runtimeConfig.growthBookKey ?? '',
            enableDevMode: false,
            subscribeToChanges: false,
            backgroundSync: false
        });
        gb.loadFeatures();
    }

    const httpLink = split(
        (operation) =>
            // If feature flag is on, don't batch requests
            !gb.isOn('dont-batch-graphql-requests') &&
            // If feature flag is off, check if the operation can be batched
            (operation.getContext().batch === undefined || operation.getContext().batch === true),
        new BatchHttpLink(batchHttpLinkParams),
        new HttpLink(httpLinkParams)
    );

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.forEach((error) => {
                // const { message, locations, path } = error;
                // TODO - Log this error somewhere?
            });

        if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    const checkLoggedIn = new ApolloLink((operation, forward) => {
        return forward(operation).map((response) => {
            const context = operation.getContext();

            // Update the user ID if not confirm email
            if (operation.operationName !== 'ConfirmEmail') {
                const xUserId = context.response.headers.get('x-userid');
                if (xUserId && parseInt(xUserId) > 0) {
                    gb.setAttributes({
                        userId: parseInt(xUserId)
                    });

                    store.dispatch(updateUserId(parseInt(xUserId)));
                } else {
                    gb.setAttributes({
                        userId: null
                    });

                    store.dispatch(updateUserId(0));
                }    
            }
        
            /**
             * On the server-side, make sure we pass on any set-cookie
             * headers from graphql. We use `raw` here to ensure we get
             * an array of cookies rather than a comma-seperated string
             */
            if (typeof window === 'undefined') {
                context.response.headers.raw()['set-cookie']?.forEach((c: string) => {
                    store.dispatch(setCookie(c));
                });
            }

            return response;
        });
    });

    return from([errorLink, checkLoggedIn.concat(httpLink)]);
}
