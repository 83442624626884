import { useEffect, useState } from 'react';
import { targetingParamType } from '../../store/adverts/reducer';
import config from '../../config';

interface useDFPSlotProps {
    path: string;
    size: googletag.GeneralSize | googletag.MultiSize | googletag.SingleSize;
    sizeMapping?: googletag.SizeMappingArray;
    targetingArguments?: Map<string, string>;
    pageTargetingArguments?: targetingParamType;
    id: string;
    inView: boolean;
    active: boolean;
    freeStarAds: string[];
}

function getTargetingChangeString(
    pageType?: string,
    forumId?: any,
    threadId?: any,
    pageNumber?: any
) {
    return `${pageType}_${forumId}_${threadId}_${pageNumber}`;
}

export default function ({
    path,
    size,
    sizeMapping,
    targetingArguments,
    pageTargetingArguments,
    id,
    inView,
    active,
    freeStarAds
}: useDFPSlotProps): void {
    const [slot, setSlot] = useState<googletag.Slot | null>(null);
    let slotPath: string = `/${config.dfp_network_id}/${path}`;
    const targetingChangeString = getTargetingChangeString(
        pageTargetingArguments?.p_t,
        pageTargetingArguments?.p_f_id,
        pageTargetingArguments?.p_t_id,
        pageTargetingArguments?.p_t_p
    );

    function createAdUnit() {
        window.googletag = window.googletag || { cmd: [] };
        googletag.cmd.push(function () {
            // @ts-ignore
            let adUnit: Slot | undefined | null = googletag
                .pubads()
                .getSlots()
                .filter((slot) => {
                    // @ts-ignore
                    return slot.getSlotId().getDomId() == id;
                })[0];
            if (!adUnit) {
                adUnit = googletag.defineSlot(slotPath, size, id);
            }

            if (adUnit) {
                adUnit.addService(googletag.pubads());
                googletag.display(adUnit);
                if (sizeMapping) {
                    adUnit.defineSizeMapping(sizeMapping);
                }

                setSlot(adUnit);
            }

            setSlot(adUnit);
        });
    }

    useEffect(() => {
        if (inView && active && !slot) {
            createAdUnit();
        }
    }, [inView, active]);

    // Refresh ad units whenever the targeting changes
    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                window.googletag = window.googletag || { cmd: [] };

                googletag.cmd.push(function () {
                    if (slot && typeof window !== 'undefined') {
                        if (targetingArguments) {
                            for (const [key, value] of Object.entries(targetingArguments)) {
                                slot.setTargeting(key, value.toString());
                            }
                        }
                        if (
                            typeof window.freestar !== 'undefined' &&
                            freeStarAds.includes(path)
                        ) {
                            window.freestar.config.enabled_slots.push({ placementName: path, slotId: id });
                        } else {
                            googletag.pubads().refresh([slot]);
                        }
                    }
                });
            }, 500);
        }
    }, [inView, targetingChangeString, slot]);
}
