import { gql } from 'graphql-tag';

export const GET_USER_DETAILS = gql`
    query GetUserDetails($userId: Int!) {
        user(id: $userId) {
            id
            forum {
                avatar
                usergroups {
                    primary
                    display
                    id
                    usertitle
                    description
                }
            }
            username
            usergroup_id
        }
    }
`;

export const GET_IGNORE_LIST = gql`
    query GetIgnoreList {
        userIgnoreList {
            ignoredUsers
            total_results
        }
    }
`;

export const GET_OWN_USER_DETAILS = gql`
    query GetOwnUserDetails($userId: Int!) {
        user(id: $userId) {
            id
            forum {
                avatar
                usergroups {
                    primary
                    display
                    id
                    usertitle
                    description
                }
            }
            username
            usergroup_id
            dob
            gender
            email
        }
    }
`;

export const CHECK_LOGGED_IN = gql`
    query Query {
        checkLoggedIn
    }
`;

export const GET_USER_PROFILE = gql`
    query GetUserProfile {
        userProfile {
            career_phase
            current_qualifications {
                id
                qualification_id
                start_year
                end_year
            }
            future_qualifications {
                id
                qualification_id
                start_year
                end_year
            }
            year_group
            current_subjects
            future_subjects
            future_learning_providers
            current_learning_providers
            clearing_opt_in
            student_loans
            first_name
            last_name
            country
            city
            email
            mobile
            post_code
            gender
            last_updated
            questions_answered
            email_marketing_preferences {
                code
                frequency
            }
            sms_marketing_preferences {
                code
                frequency
            }
            topics_of_interest {
                code
                interested
            }
            email_opted_out_at
            sms_opted_out_at
            international_application {
                country_of_nationality
              }
        }
    }
`;

export const GET_USER_SEGMENTS = gql`
    query getUserSegments {
        userSegments {
            main
            super
            high
            medium
            low
            all
            adverts
        }
    }
`;

export const GET_FOLLOWED_USERS = gql`
    query getFollowedUsers($userId: Int!, $perPage: Int, $page: Int) {
        user_following_list(id: $userId, per_page: $perPage, page: $page) {
            id
        }
    }
`;

export const IS_FOLLOWING_USER = gql`
    query isFollowing($userId: Int) {
        isFollowing(user_id: $userId)
    }
`;

export const FOLLOW_USER = gql`
    mutation followUser($userId: Int) {
        followUser(user_id: $userId)
    }
`;

export const UNFOLLOW_USER = gql`
    mutation unfollowUser($userId: Int) {
        unfollowUser(user_id: $userId)
    }
`;

export const SEND_EMAIL_CONFIRMATION_LINK = gql`
    mutation sendEmailConfirmationLink($email: String!) {
        sendEmailConfirmationLink(email: $email) {
            valid
            error
        }
    }
`;

export const MARK_PROFILE_DATA_AS_CORRECT = gql`
    mutation markProfileDataAsCorrect {
        markProfileDataAsCorrect {
            valid
            error
        }
    }
`;
